<template>
  <div>
    <section class="card-container">
      <div class="form-wrapper">
        <p class="title">{{ pageTitle }}</p>
        <div class="hint">
          <el-tooltip placement="right">
            <div slot="content">
              <p>點選連結註冊登入後貼標</p>
            </div>
            <div class="flex items-center" style="gap: 12px">
              <p>連結貼標說明</p>
              <span class="material-icons" style="width: 24px">
                help_outlined
              </span>
            </div>
          </el-tooltip>
        </div>
        <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
          <BaseElFormItem label="連結名稱" prop="name">
            <template slot="label">
              <FormItemTooltipLabel label="連結名稱" :tooltipWidth="230">
                此名稱會顯示於貼標頁面上讓顧客看到
              </FormItemTooltipLabel>
            </template>
            <BaseElInput v-model="formData.name" clearable placeholder="請輸入" />
          </BaseElFormItem>
          <!-- <BaseElFormItem label="原始連結" prop="targetLink">
            <BaseElInput v-model="formData.targetLink" clearable :disabled="!!linkId" placeholder="請輸入欲追蹤連結" />
          </BaseElFormItem> -->
          <BaseElFormItem label="註冊標籤" prop="registerMTags">
            <BaseElSelect
              ref="registerMTags"
              v-model="formData.registerMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('registerMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.registerMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="活動標籤" prop="targetMTags">
            <BaseElSelect
              ref="targetMTags"
              v-model="formData.targetMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('targetMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.targetMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="連結開始時間" prop="start">
            <el-date-picker
              v-model="formData.start"
              editable
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              default-time="00:00:00"
              :picker-options="pickerOptions"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
          <BaseElFormItem label="連結結束時間" prop="end">
            <el-date-picker
              v-model="formData.end"
              editable
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              default-time="23:59:59"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>

    <MemberTagAdvanceSelect
      enableCreate
      :show.sync="dialog.tagSelect"
      :modelValue="formData[tagsData]"
      :options="customTags"
      :max="tagsData === 'registerMTags' ? 1: 0"
      @update:modelValue="getSelectTag"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'
import { CreateClientMTagLink, FindClientMTagLink, UpdateClientMTagLink } from '@/api/clientMTagLink'
import formUtils from '@/utils/form'
import { map, omit, get, filter } from 'lodash'
import { noEmptyRules } from '@/validation'
import dayjs from '@/lib/dayjs'
import { useTagStore } from './components/useTagStore'
import { defineComponent, onMounted, computed, ref, reactive, set } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { getRefsVue3 } from '@/utils/helper'

export default defineComponent({
  name: 'EditTrackingLink',
  components: { MemberTagAdvanceSelect, FormItemTooltipLabel },
  setup () {
    const { tags, getAllTags } = useTagStore()
    const { shopId } = useShop()
    const route = useRoute()
    const router = useRouter()
    const formRef = ref(null)
    const registerMTags = ref(null)
    const targetMTags = ref(null)
    const loading = ref(false)
    const tagsData = ref([])
    const dialog = reactive({
      tagSelect: false,
    })
    const pickerOptions = {
      disabledDate (time) {
        return time.getTime() < dayjs(Date.now()).subtract(1, 'd')
      },
    }
    const formData = reactive({
      name: null,
      // targetLink: null,
      start: null,
      end: null,
      registerMTags: [],
      registerMTagsOptions: [],
      targetMTags: [],
      targetMTagsOptions: [],
    })
    const formRules = {
      name: [noEmptyRules()],
      // targetLink: [noEmptyRules(), urlRules()],
      start: [noEmptyRules()],
      end: [noEmptyRules()],
      registerMTags: [noEmptyRules()],
      targetMTags: [noEmptyRules()],
    }

    const linkId = computed(() => get(route, 'params.id'))
    const customTags = computed(() => filter(tags.value, item => !item.isSystem))
    const pageTitle = computed(() => {
      if (linkId.value) return '編輯連結'
      return '新增連結'
    })
    const findTrackingLink = async () => {
      const [res, err] = await FindClientMTagLink({
        shopId: shopId.value,
        id: linkId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      syncFormData(res)
    }
    const syncFormData = (res) => {
      formData.name = res.name
      // formData.targetLink = res.targetLink
      formData.start = res.start
      formData.end = res.end
      formData.registerMTags = map(res.RegisterMTags, 'id')
      formData.registerMTagsOptions = res.RegisterMTags
      formData.targetMTags = map(res.TargetMTags, 'id')
      formData.targetMTagsOptions = res.TargetMTags
    }
    const openSelectTag = (type) => {
      const refs = {
        registerMTags: registerMTags,
        targetMTags: targetMTags,
      }
      if (refs[type]) {
        const refTarget = getRefsVue3(this, refs[type].value)
        if (refTarget) refTarget.blur()
      }
      tagsData.value = type
      dialog.tagSelect = true
    }
    const handleConfirm = async () => {
      const isEdit = linkId.value
      if (isEdit) await updateTrackingLink()
      if (!isEdit) await createTrackingLink()
    }
    const createTrackingLink = async () => {
      loading.value = true
      if (!(await formUtils.checkForm(formRef.value))) {
        loading.value = false
        return
      }
      if (!checkStartDate()) {
        loading.value = false
        return
      }
      const form = omit(formData, ['registerMTagsOptions', 'targetMTagsOptions'])
      const [, err] = await CreateClientMTagLink({
        shopId: shopId.value,
        ...form,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功！')
      router.push({ name: 'ClientMTagLinkManagement' })
    }
    const updateTrackingLink = async () => {
      loading.value = true
      if (!(await formUtils.checkForm(formRef.value))) {
        loading.value = false
        return
      }
      if (!checkStartDate()) {
        loading.value = false
        return
      }
      const form = omit(formData, ['registerMTagsOptions', 'targetMTagsOptions'])
      const [, err] = await UpdateClientMTagLink({
        shopId: shopId.value,
        id: linkId.value,
        ...form,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      router.push({ name: 'ClientMTagLinkManagement' })
    }
    const getSelectTag = (val) => {
      set(formData, tagsData.value, [...val])
      set(formData, `${tagsData.value}Options`, filter(tags.value, i => val.includes(i.id)))
    }
    const checkStartDate = () => {
      const start = formData.start
      const end = formData.end
      if (dayjs(start).isAfter(dayjs(end))) {
        window.$message.error('結束時間不可早於開始時間')
        return false
      }
      return true
    }
    onMounted(async () => {
      getAllTags()
      if (linkId.value) await findTrackingLink()
    })
    return {
      customTags,
      loading,
      tagsData,
      dialog,
      pickerOptions,
      formData,
      formRules,
      pageTitle,
      openSelectTag,
      handleConfirm,
      getSelectTag,
      formRef,
      registerMTags,
      targetMTags,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  @apply text-primary-100 font-bold text-lg leading-[21.6px] ;
}
.form-wrapper {
  @apply p-[20px] pl-[29px];
}
.hint {
  @apply inline-flex py-[22px];
}
</style>
