import { admin2 } from './instance'

export const GetClientMTagLink = async ({
  shopId,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/clientMTagLink`,
    params: {
      start,
      limit,
    },
  })
}

export const GetClientMTagLinkCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/clientMTagLink/count`,
  })
}

export const FindClientMTagLink = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/clientMTagLink/${id}`,
  })
}

// export const FindViewCount = async ({
//   shopId,
//   ids,
// }) => {
//   return admin2({
//     method: 'GET',
//     url: `/${shopId}/clientMTagLink/viewCount`,
//     params: {
//       ids,
//     },
//   })
// }

export const CreateClientMTagLink = async ({
  shopId,
  name,
  // targetLink,
  start,
  end,
  targetMTags,
  registerMTags,
  // actionMTags,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/clientMTagLink`,
    data: {
      name,
      // targetLink,
      start,
      end,
      targetMTags,
      registerMTags,
      // actionMTags,
    },
  })
}

export const UpdateClientMTagLink = async ({
  shopId,
  id,
  name,
  start,
  end,
  targetMTags,
  registerMTags,
  // actionMTags,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/clientMTagLink/${id}`,
    data: {
      name,
      start,
      end,
      targetMTags,
      registerMTags,
      // actionMTags,
    },
  })
}

export const DeleteClientMTagLink = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/clientMTagLink/${id}`,
  })
}
